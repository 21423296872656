.ManageOnboardingBenefitsForm {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 16px;
    background-color: #fff;
  
    .ManageOnboardingBenefitsForm__form {
      display: flex;
      flex-direction: column;
      gap: 10px;
  
      .sectionHeader {
        margin-top: 8px;
        margin-bottom: 12px;
  
        h5 {
          font-size: 18px;
          font-weight: 600;
          color: #333;
          border-bottom: 2px solid #e0e0e0;
          padding-bottom: 4px;
        }
      }
  
      .imageUploadSection {
        margin-top: 8px;
  
        .imagePreview {
          margin-top: 8px;
          display: flex;
          justify-content: left;
  
          .previewImage {
            width: 100%;
            max-width: 150px;
            height: auto;
            border-radius: 8px;
            border: 1px solid #e0e0e0;
          }
        }
      }
  
      .checkboxArea {
        display: flex;
        align-items: center;
      }
  
      .buttonArea {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
  
        button {
          padding: 8px 16px;
          font-size: 14px;
          border-radius: 8px;
        }
      }
  
      .MuiTextField-root {
        margin-top: 8px;
      }
    }
  }
  .manage-onboarding-benefits__image-preview {
    display: flex;
    justify-content: flex-start; 
    margin-top: 8px;
  }
  
  .manage-onboarding-benefits__image-preview-img {
    width: 100px; 
    height: 100px; 
    object-fit: cover; 
    border-radius: 8px; 
    margin-top: 4px;
  }
  
  .spAdminDialogTitle {
    background-color: #f5f5f5;
    padding: 16px;
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }
  
  