@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.valmikiImg {
  width: 100%;
}

.rotating-image-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-left: 2rem;
}

.animated-image {
  width: 100%;
  height: 100%;
  animation: rotateAndMove 4s ease-in-out infinite alternate;
}

.valmikiImage {
  .gridLeft {
    max-width: 50% !important;
  }

  .gridRight {
    max-width: 50% !important;
  }
}

.valmikiBannerText {
  position: relative;
  //padding-top: 2rem;
  right: 2rem;
  text-align: right;

  .betaText {
    font-size: 1rem;
    font-weight: bold;
    color: #a8a7a7;
  }

  .valmikiTitle {
    font-family: "Playfair Display", serif;
    font-size: 4rem;
    font-weight: bold;
    line-height: 0.64;
    color: #d56939;
    margin: 0.5rem 0 0.5rem 0;
  }

  .valmikiSubTitle {
    font-size: 0.9rem;
    font-weight: 600;
    color: #7e7676;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .animatedCircle {
    display: flex;
    justify-content: end;

    .demoBtn {
        width: 88%;
        align-items: flex-start;
        padding: 8px 0;
        font-size: 14px;
        background: linear-gradient(90deg, #db4250, #f49e49);
        border-radius: 6px;
        box-shadow: none;
        margin-top: 20px;
        transition: .3s ease;
    
        @media (min-width: 340px) and (max-width: 390px) {
          font-size: 13px;
        }
    
        &:hover {
          background: linear-gradient(90deg, #ff4949, #ff8b2d);
        }
    
        span {
          margin-left: 2px;
    
          svg {
            font-size: 22px;

            @media (min-width: 340px) and (max-width: 390px) {
              font-size: 21px;
            }
          }
        }
    }
  }
}

.videoModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: end;

  iframe {
    width: 100%;
    height: 335px;

    @media (min-width: 360px) and (max-width: 390px) {
      height: 372px;
    }

    @media (min-width: 391px) and (max-width: 420px) {
      height: 400px;
    }

    @media (min-width: 421px) and (max-width: 460px) {
      height: 428px;
    }

    @media (min-width: 461px) and (max-width: 540px) {
      height: 460px;
    }

    @media (min-width: 541px) and (max-width: 580px) {
      height: 300px;
    }

    @media (min-width: 581px) and (max-width: 640px) {
      height: 320px;
    }
  }
}

.demoVideoCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 0 !important;

  &:hover {
    background-color: transparent;
  }

  svg {
    color: #fff;
    font-size: 28px;
    background-color: #d65758;
    padding: 4px;
    border-radius: 50%;

    &:hover {
      background-color: #b04445;
    }
  }
}

// @keyframes rotateAndMove {
//   0% {
//     transform: rotate(0deg) translateX(0);
//   }
//   25% {
//     transform: rotate(20deg) translateX(-20px);
//   }
//   50% {
//     transform: rotate(0deg) translateX(20px);
//   }
//   75% {
//     transform: rotate(-20deg) translateX(-20px);
//   }
//   100% {
//     transform: rotate(0deg) translateX(0);
//   }
// }

@media (min-width: 769px) {
  .valmikiImage {
    .gridLeft {
      .valmikiImg {
        max-width: 200px;
        margin: auto;
      }
    }
    .gridRight {
      display: flex;
      justify-content: center;
      align-items: center;

      .valmikiBannerText {
        //padding-top: 1rem;
        .valmikiTitle {
          font-size: 4rem;
        }
        .valmikiSubTitle {
          margin-bottom: 0;
        }
      }
      .rotating-image-container {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-left: 2rem;
    }
    }
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .valmikiImage {
    .gridLeft {
      .valmikiImg {
        max-width: 300px;
        margin: auto;
      }
    }

    .gridRight {
      display: flex;
      justify-content: center;
      align-items: center;

      .valmikiBannerText {
        .valmikiTitle {
          font-size: 5rem;
        }
      }
      .rotating-image-container {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-left: 2rem;
    }
    }
  }
}

@media (min-width: 481px) and (max-width: 599px) {
  .valmikiImage {
    .gridLeft {
      .valmikiImg {
        max-width: 300px;
        margin: auto;
      }
    }

    .gridRight {
      display: flex;
      justify-content: center;
      align-items: center;

      .valmikiBannerText {
        .valmikiTitle {
          font-size: 4rem;
        }
      }
      .rotating-image-container {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-left: 2rem;
    }
    }
  }
}

@media (max-width: 480px) {
  .valmikiImage {
    .gridLeft {
      .valmikiImg {
        max-width: 300px;
        margin: auto;
      }
    }

    .gridRight {
      display: flex;
      justify-content: center;
      align-items: center;

      .valmikiBannerText {
       // padding-top: 1rem;
        .valmikiTitle {
          font-size: 3rem;
        }
        .valmikiSubTitle {
          margin-bottom: 0;
        }
      }
      .rotating-image-container {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-left: 2rem;
    }
    }
  }
}
