.spChadhavaCardContainer {
    display: flex;
    border-radius: 16px !important;
    border: 1px solid #cdcdcd;
    box-shadow: none !important;
    margin: 0px 6px 25px 6px;

    .spChadhavaCardContent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
        padding: 16px 12px;

        .leftSection {
            .title {
                font-size: 16px;
                color: #181818;
                font-weight: bold;
                margin-bottom: 4px;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                line-height: 1.5;
                max-height: 4.5em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
            }

            .price {
                font-size: 14px;
                color: #676767;
                font-weight: 500;
            }

            .devoteeBookedCount {
                .numberText {
                    font-size: 14px;
                    color: #333;

                    span {
                        color: #d56939;
                        font-weight: 500;
                    }
                }
            }

            .moreInfoBtn {
                margin-top: 7px;

                button {
                    font-size: 12px;
                    text-transform: capitalize;
                    padding: 4px 12px;
                    border-radius: 20px;

                    span {
                        margin-left: 0;

                        svg {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .rightSection {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            position: relative;
            //margin-top: 8px;

            .chadhavaItemImg {
                width: 85px;
                height: 85px;
                border-radius: 8px;
            }

            .addButton {
                position: absolute;
                left: 0;
                right: 0;
                bottom: -20px;
                margin: auto 8px;
                text-align: center;
                background-color: #ffece3;
                color: #d56939;
                font-weight: bold;
                padding: 2px;
                text-transform: none;
                border: 1px solid #d37045;
                box-shadow: none;
                transition: 0.3s ease;

                &:hover {
                    border: 1.2px solid #d56939;
                    box-shadow: none;
                }
            }
        }
    }
}