.react-multiple-carousel__arrow--left {
    left: calc(2% + 1px);
}

.react-multiple-carousel__arrow--right {
    right: calc(2% + 1px);
}

.react-multiple-carousel__arrow {
    min-width: 26px;
    min-height: 26px;
    border-radius: 50%;

    &::before {
        font-size: 16px;
    }
}

.react-multi-carousel-list {
    .react-multi-carousel-dot-list {
        .react-multi-carousel-dot {
            button {
                border: none;
                width: 10px;
                height: 10px;
                background: rgba(213, 105, 57, 0.3);
            }
        }

        .react-multi-carousel-dot--active button {
            background: #d56939;
        }
    }
}