.offerCarousel {
    justify-content: flex-start;

    &.singleItem {
        justify-content: center;

        @media (max-width: 767px) {
            justify-content: flex-start;
        }
    }
}